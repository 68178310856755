export const defaultState = {
  sponsors: [],
  verticals: [],
  prescriptions: {},
  pubnubChannelGroup: [],
  endReasons: [],
  // filters: [
  //   { name: 'All', isSelected: false },
  //   { name: 'Unattented', isSelected: false },
  //   { name: 'Ongoing', isSelected: false },
  //   { name: 'Followups', isSelected: false },
  //   { name: 'Completed', isSelected: false },
  //   { name: 'Digitized', isSelected: false },
  //   { name: 'Invalid', isSelected: false },
  //   { name: 'MyCases', isSelected: false }
  // ],
  // selectedSponsorId: null,
  canPaginate: false,
  paginateToken: 2,
  fetchConsultsUrlForPagination: null
};

export default function info(state = defaultState, action) {
  switch (action.type) {
    case 'SET_INFO':
      return Object.assign({}, state, {
        sponsors: [
          { sponsorId: null, sponsorName: 'Select None', value: null },
          ...action.payload.sponsors
        ],
        filters: [
          {doctorId:null, doctorName: 'Select None', value:null },
          ...action.payload.filters
        ],
        verticals: action.payload.verticals,
        prescriptions: action.payload.prescriptions,
        pubnubChannelGroup: action.payload.pubnubChannelGroup,
        endReasons: action.payload.endReasons,
        heightMandatory: action.payload.heightMandatory,
        weightMandatory: action.payload.weightMandatory
      });
    // case 'TOGGLE_FILTER': {
    //   const filters = [...state.filters];
    //   filters[action.payload.index].isSelected = !filters[action.payload.index]
    //     .isSelected;
    //   return Object.assign({}, state, {
    //     filters
    //   });
    // }
    // case 'SET_SELECTED_SPONSOR_ID': {
    //   return Object.assign({}, state, {
    //     selectedSponsorId: action.payload.selectedSponsorId
    //   });
    // }
    case 'SET_FETCH_CONSULTS_URL_FOR_PAGINATION': {
      return Object.assign({}, state, {
        fetchConsultsUrlForPagination: action.payload.url,
        canPaginate: true,
        paginateToken: 2
      });
    }
    case 'ADD_CONSULTS': {
      const consultsArrayLength = action.payload.consults.length;
      return Object.assign({}, state, {
        canPaginate: consultsArrayLength > 0 ? true : false,
        paginateToken: consultsArrayLength > 0 ? state.paginateToken + 1 : 2
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
