export const defaultState = {
  consults: [],
  unrepliedChannel: null,
  fetchingConsults: false,
  activeConsult: {
    channel: null,
    consultationId: null,
    user: {
      userName: '',
      userId: null,
      gender: '',
      age: null,
      relativeName: null,
      dob: null,
    },
    doctorType: '',
    sponsor: { sponsorName: '', id: 0 },
    issues: '',
    doctorTimetoken: null,
    userTimetoken: null,
    labels: [],
    files: [],
  },
  currentUserInfo: null,
  consultPatientInfo: null,
  invalidReasons:[],
  quickReplySuggestions:[],
  activeConsultChannels: {
    consultationId: null,
    channels: [],
  }
};

export default function consults(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CONSULTS':
      return Object.assign({}, state, {
        consults: action.payload.consults || [],
        fetchingConsults: false,
      });
    case 'SET_FETCHING_CONSULTS':
      return Object.assign({}, state, {
        fetchingConsults: true,
      });
    case 'SET_ACTIVE_CONSULT':
      return Object.assign({}, state, {
        activeConsult: action.payload.consult,
      });
    case 'SET_ACTIVE_CONSULT_CHANNELS':
      return Object.assign({}, state, {
        activeConsultChannels: action.payload,
      });
    case 'ADD_FILES_TO_ACTIVE_CONSULT': {
      return Object.assign({}, state, {
        activeConsult: {
          ...state.activeConsult,
          files: action.payload.files,
        },
      });
    }
    case 'ADD_CONSULTS': {
      return Object.assign({}, state, {
        consults: [...state.consults, ...action.payload.consults],
        fetchingConsults: false,
      });
    }
    case 'SET_UNREPLIED_CHANNEL': {
      console.log('setting unreplied channel', action);
      return Object.assign({}, state, {
        unrepliedChannel: action.payload,
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    case 'SET_CONSULT_USER_INFO': {
      return Object.assign({}, state, {
        currentUserInfo: action.payload,
      });
    }
    case 'SET_CONSULTATION_PATIENT_INFO': {
      return Object.assign({}, state, {
        consultPatientInfo: action.payload,
      });
    }
    case 'SET_DIGITISATION_INVALID_REASONS': {
      return Object.assign({}, state, {
        invalidReasons: action.payload,
      });
    }
    case 'SET_QUICK_REPLY_SUGGESTIONS': {
      return Object.assign({}, state, {
        quickReplySuggestions: action.payload,
      });
    }
    default:
      return state;
  }
}
