export const defaultState = {
  sessionId: null,
  consultationId: null,
  patientName: null,
  doctorName: null,
  status: null,
  badTags: [
    'I could not hear any sound',
    'I could not see any video',
    'Call ended unexpectedly',
    'The other side could not hear/see me',
  ],
  averageTags: [
    'I heard noise/echo in the call',
    'Video kept freezing',
    'Image quality was poor',
    'Audio quality was poor',
    'Call ended unexpectedly',
  ],
  moreTags: ['Video quality was not perfect', 'Audio quality was not perfect'],
};

export default function feedbackInfo(state = defaultState, action) {
  switch (action.type) {
    case 'SET_VIDEO_FEEDBACK_INFO':
      return Object.assign(
        {},
        {
          ...state,
          ...action.payload,
        }
      );
    case 'RESET_VIDEO_FEEDBACK_INFO':
      console.log('RESET_VIDEO_FEEDBACK_INFO');
      return Object.assign(
        {},
        {
          ...defaultState,
        }
      );
    default:
      return state;
  }
}
