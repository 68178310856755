export const defaultState = {};

export default function digitsationPincode(state = defaultState, action) {
  switch (action.type) {
    case 'SET_DIGITISATION_PINCODE': {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}
