export const defaultState = {
  conditions: [],
  savedConditions: []
};

export default function conditions(state = defaultState, action) {
  switch (action.type) {
    case 'RESET_CONDITIONS': {
      return Object.assign({}, state, {
        conditions: []
      });
    }
    case 'GET_CONDITIONS': {
      console.log({ action }, 'GET_CONDITIONS');
      return Object.assign({}, state, {
        savedConditions: action.payload.conditions
      });
    }
    case 'ADD_CONDITION':
      return Object.assign({}, state, {
        conditions: [...action.payload.conditions, ...state.conditions]
      });
    case 'REMOVE_CONDITION': {
      return Object.assign({}, state, {
        conditions: state.conditions.filter(
          (condition, index) =>
            condition.conditionId !== action.payload.condition.conditionId
        )
      });
    }
    case 'CHANGE_ANSWER_CONDITION': {
      const {
        conditionId,
        questionId,
        optionGroupType,
        optionId,
        answer
      } = action.payload;

      console.log(
        { conditionId, questionId, optionGroupType, optionId, answer },
        'CHANGE_ANSWER_CONDITION'
      );

      const conditions = [...state.conditions];
      conditions.forEach(condition => {
        if (condition.conditionId === conditionId) {
          condition.questions.forEach(question => {
            if (question.questionId === questionId) {
              question.optionGroups.forEach(optionGroup => {
                if (optionGroup.type === optionGroupType) {
                  optionGroup.options.forEach(option => {
                    if (option.optionId === optionId) {
                      switch (optionGroupType) {
                        case 'single': {
                          option.answer = answer;
                          // return Object.assign({}, state, { conditions });
                          break;
                        }
                        case 'multiple': {
                          option.answer = answer;
                          // return Object.assign({}, state, { conditions });
                          break;
                        }
                        case 'text': {
                          option.answer = answer;
                          // return Object.assign({}, state, { conditions });
                          break;
                        }
                        default: {
                          console.log(
                            'This should never happen, there is always a optiongroup type'
                          );
                          // return;
                        }
                      }
                    } else {
                      switch (optionGroupType) {
                        case 'single': {
                          option.answer = false;
                          // return Object.assign({}, state, { conditions });
                          break;
                        }
                        default: {
                          console.log(
                            'This should never happen, there is always a optiongroup type'
                          );
                          // return;
                        }
                      }
                    }
                  });
                  return Object.assign({}, state, { conditions });
                }
              });
            }
          });
        }
      });
      return Object.assign({}, state, { conditions });
    }
    case 'CHANGE_EDITABLE_CONDITIONS': {
      const conditions = [...state.conditions];
      console.log({ conditions, action, state }, 'changeEditableConditions');
      conditions.forEach(condition => {
        if (condition.conditionId === action.payload.conditionId) {
          condition.isEditable = action.payload.isEditable;
        }
      });
      return Object.assign({}, state, {
        conditions: conditions
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
