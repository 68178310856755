export const defaultState = {
  prescriptionId: null,
  medicines: [],
  cartItems:[],
  template: [],
  labTests: [],
  probableDiagnosis: '',
  secondaryDiagnosis: '',
  icdCode: '',
  secondaryProbableDiagnosis: '',
  secondaryDiagnosisIcdCode: '',
  doctorName: '',
  doctorId: '',
  appointmentDate: '',
  prescriptionValidityDate: '',
  generalHealthAdvice: [],
  treatmentPlan: {},
  relativeId: null,
  digitisationConsultation: null,
  relatives: [],
  pastAppointments: [],
  isCritical: false,
  centerAddress: '',
  centerName: '',
  centerId: '',
  additionalNotes: '',
  additionalNotesSecondary: '',
  supportedPartners: { fetched: false, partners: [] },
};

export default function prescription(state = defaultState, action) {
  switch (action.type) {
    case 'RESET_PRESCRIPTION': {
      return Object.assign({}, state, defaultState, {
        pastAppointments: state.pastAppointments,
      }, { labTests: state.labTests, cartItems: state.cartItems });
    }
    case 'RESTORE_PRESCRIPTION': {
      console.log({ action }, 'RESTORE_PRESCRIPTION');
      return Object.assign({}, state, action.payload.prescription);
    }
    case 'SET_PRESCRIPTION_ID':
      return Object.assign({}, state, {
        prescriptionId: action.payload.prescriptionId,
      });
    case 'SET_MEDICINES': {
      return Object.assign({}, state, {
        medicines: [...action.payload.medicines],
      });
    }
    case 'SET_CART_ITEMS': {
      return Object.assign({}, state, {
        cartItems: action.payload.cartItems,
      });
    }
    case 'ADD_MEDICINES':
      return Object.assign({}, state, {
        medicines: [...state.medicines, ...action.payload.medicines],
      });
    case 'REMOVE_MEDICINES': {
      return Object.assign({}, state, {
        medicines: state.medicines.filter(
          (medicine, index) =>
            medicine.medicineId !== action.payload.medicine.medicineId
        ),
      });
    }
    case 'ADD_TEMPLATE':
      return Object.assign({}, state, {
        template: action.payload.template,
      });
    case 'REMOVE_TEMPLATE': {
      return Object.assign({}, state, {
        template: [],
      });
    }
    case 'SET_LAB_TESTS': {
      return Object.assign({}, state, {
        labTests: [ ...state.labTests, ...action.payload.labs ].reduce((acc, obj) => acc.some(item => item.labId === obj.labId) ? acc : [...acc, obj], []),
      });
    }
    case 'ADD_LAB_TESTS':
      return Object.assign({}, state, {
        labTests: [...state.labTests, ...action.payload.labTests].reduce((acc, obj) => acc.some(item => item.labId === obj.labId) ? acc : [...acc, obj], []),
      });
    case 'REMOVE_LAB_TEST': {
      return Object.assign({}, state, {
        labTests: state.labTests.filter(
          (lab, index) => lab.labId !== action.payload.lab.labId
        ),
      });
    }
    case 'SET_ADVICES': {
      return Object.assign({}, state, {
        generalHealthAdvice: [...action.payload.advices],
      });
    }
    case 'ADD_ADVICES':
      return Object.assign({}, state, {
        generalHealthAdvice: [
          ...state.generalHealthAdvice,
          ...action.payload.advices,
        ],
      });
    case 'REMOVE_ADVICE': {
      return Object.assign({}, state, {
        generalHealthAdvice: state.generalHealthAdvice.filter(
          (advice, index) => advice.adviceId !== action.payload.advice.adviceId
        ),
      });
    }
    case 'SET_PROBABLE_DIAGNOSIS':
      return Object.assign({}, state, {
        probableDiagnosis: action.payload,
      });
      case 'SET_SECONDARY_PROBABLE_DIAGNOSIS':
      return Object.assign({}, state, {
        secondaryProbableDiagnosis: action.payload,
      });
      case 'SET_SECONDARY_DIAGNOSIS':
      return Object.assign({}, state, {
        secondaryDiagnosis: action.payload,
      });
      case 'SET_ICD_CODE':
        return Object.assign({}, state, {
          icdCode: action.payload,
        });
        case 'SET_SECONDARY_ICD_CODE':
        return Object.assign({}, state, {
          secondaryDiagnosisIcdCode: action.payload,
        });
    case 'SET_DOCTOR_NAME':
      return Object.assign({}, state, {
        doctorName: action.payload,
      });
    case 'SET_IS_CRITICAL':
      return Object.assign({}, state, {
        isCritical: action.payload,
      });
    case 'SET_CENTER_NAME':
      return Object.assign({}, state, {
        centerName: action.payload,
      });
    case 'SET_CENTER_ID':
      return Object.assign({}, state, {
        centerId: action.payload,
      });
    case 'SET_CENTER_ADDRESS':
      return Object.assign({}, state, {
        centerAddress: action.payload,
      });
    case 'SET_APPOINTMENT_DATE':
      return Object.assign({}, state, {
        appointmentDate: action.payload,
      });
    case 'SET_PRESCRIPTION_VALIDITY_DATE':
      return Object.assign({}, state, {
        prescriptionValidityDate: action.payload,
      });
    case 'SET_GENERAL_HEALTH_ADVICE':
      return Object.assign({}, state, {
        generalHealthAdvice: action.payload,
      });
    case 'SET_TREATMENT_PLAN':
      return Object.assign({}, state, {
        treatmentPlan: action.payload.treatmentPlan,
      });
    case 'SET_RELATIVE_ID': {
      return Object.assign({}, state, {
        relativeId: action.payload,
      });
    }
    case 'SET_DIGITISATION_CONSULTATION': {
      return Object.assign({}, state, {
        digitisationConsultation: action.payload,
      });
    }
    case 'SET_PAST_APPOINTMENTS': {
      return Object.assign({}, state, {
        pastAppointments: action.payload.consultations || [],
      });
    }
    case 'SET_RELATIVES':
      return Object.assign({}, state, {
        relatives: action.payload.relatives || [],
      });
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    case 'SET_DOCTOR_ID':
      return Object.assign({}, state, {
        doctorId: action.payload,
      });
      case 'SET_IS_CENTRE_BLACKLISTED':
        return Object.assign({}, state, {
          isBlacklistedCentre: action.payload,
        });
        case 'SET_CENTRE_BLACKLISTED_NAME':
        return Object.assign({}, state, {
          blacklistedCentreName: action.payload,
        });
    case 'SET_ADDITIONAL_NOTES':
      return Object.assign({}, state, {
        additionalNotes: action.payload,
      });
    case 'SET_ADDITIONAL_NOTES_SECONDARY':
      return Object.assign({}, state, {
        additionalNotesSecondary: action.payload,
      });
    case 'ADD_LABS_AND_MEDICINES':
      return Object.assign({}, state, {
        labTests: [...(action.payload.labs || [])],
        cartItems: [...(action.payload.medicines || [])],
      });
    case 'SET_SUPPORTED_PARTNERS':
      return Object.assign({}, state, {
        supportedPartners: { fetched: true, partners: action.payload.partners }
      })
    default:
      return state;
  }
}
