import { store } from "../store/index";

export const showError = err => {
  let errorTitle = "Something went wrong";
  let errorDesc = "Check your internet connection and try again";

  if (err.response && err.response.data && err.response.data.errorMessage) {
    errorDesc = err.response.data.errorMessage;
  } else if (err && err.data && err.data.errorMessage) {
    errorDesc = err.data.errorMessage;
  }

  store.dispatch({
    type: "SHOW_ERROR",
    payload: {
      errorTitle: errorTitle,
      errorDesc: errorDesc
    }
  });
};
