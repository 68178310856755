import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

export function loginUser(email, password, dispatch) {
  const body = {
    email,
    password,
    v: config.version,
    platform: 'WEB',
  };
  console.log(body);

  return axios
    .post(`${config.apiBaseUrl}/new-auth/doctor/login`, body)
    //.post(`http://localhost:5001/new-auth/doctor/login`, body)
    .then(res => {
      const responseBody = res.data;
      const responseHeaders = res.headers;
      if (responseBody.message === 'loggedIn') {
        return {
          body: responseBody,
          headers: responseHeaders,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch(err => {
      showError(err);
      dispatch({ type: 'LOGIN_ERROR', payload: { reset: true } });
      throw err;
    });
}

export function goActive(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios.post(`${config.apiBaseUrl}/new-doctor/active`, {
    source: 'DASHBOARD'
  }, {
    headers,
  })
    .then(res => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function goInactive(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios.post(`${config.apiBaseUrl}/new-doctor/inactive`, {
    source: 'DASHBOARD'
  } , {
    headers,
  })
    .then(res => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function goOfflineActive(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios.post(`${config.apiBaseUrl}/new-doctor/inhouse-active`, {
    source: 'DASHBOARD'
  }, {
    headers,
  })
    .then(res => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function goOfflineInactive(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios.post(`${config.apiBaseUrl}/new-doctor/inhouse-inactive`, null, {
    headers,
  })
    .then(res => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}
