export const defaultState = {
  symptoms: [
    {
      symptomId: null,
      symptomName: null,
      questions: [
        {
          questionId: null,
          questionText: null,
          optionGroups: [
            {
              type: null,
              options: [
                {
                  optionId: null,
                  optionText: null,
                  answer: null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default function symptoms(state = defaultState, action) {
  switch (action.type) {
    case 'RESET_SYMPTOMS': {
      return Object.assign({}, state, defaultState);
    }
    case 'RESTORE_SYMPTOMS': {
      console.log({ action }, 'RESTORE_SYMPTOMS');
      return Object.assign({}, state, action.payload.prescription);
    }
    case 'GET_SYMPTOMS': {
      console.log({ action }, 'GET_SYMPTOMS');
      return Object.assign({}, state, {
        symptoms: action.payload.symptoms
      });
    }
    case 'ADD_SYMPTOM':
      return Object.assign({}, state, {
        symptoms: [...action.payload.symptoms, ...state.symptoms]
      });
    case 'REMOVE_SYMPTOM': {
      return Object.assign({}, state, {
        symptoms: state.symptoms.filter(
          (symptom, index) =>
            symptom.symptomId !== action.payload.symptom.symptomId
        )
      });
    }
    case 'CHANGE_ANSWER': {
      const {
        symptomId,
        questionId,
        optionGroupType,
        optionId,
        answer
      } = action.payload;

      console.log(
        { symptomId, questionId, optionGroupType, optionId, answer },
        'CHANGE_ANSWER'
      );

      const symptoms = [...state.symptoms];
      symptoms.forEach(symptom => {
        if (symptom.symptomId === symptomId) {
          symptom.questions.forEach(question => {
            if (question.questionId === questionId) {
              question.optionGroups.forEach(optionGroup => {
                if (optionGroup.type === optionGroupType) {
                  optionGroup.options.forEach(option => {
                    if (option.optionId === optionId) {
                      switch (optionGroupType) {
                        case 'single': {
                          option.answer = answer;
                          // return Object.assign({}, state, { symptoms });
                          break;
                        }
                        case 'multiple': {
                          option.answer = answer;
                          // return Object.assign({}, state, { symptoms });
                          break;
                        }
                        case 'text': {
                          option.answer = answer;
                          // return Object.assign({}, state, { symptoms });
                          break;
                        }
                        default: {
                          console.log(
                            'This should never happen, there is always a optiongroup type'
                          );
                          // return;
                        }
                      }
                    } else {
                      switch (optionGroupType) {
                        case 'single': {
                          option.answer = false;
                          // return Object.assign({}, state, { symptoms });
                          break;
                        }
                        default: {
                          console.log(
                            'This should never happen, there is always a optiongroup type'
                          );
                          // return;
                        }
                      }
                    }
                  });
                  return Object.assign({}, state, { symptoms });
                }
              });
            }
          });
        }
      });
      return Object.assign({}, state, { symptoms });
    }
    case 'CHANGE_EDITABLE': {
      const symptoms = [...state.symptoms];
      symptoms.forEach(symptom => {
        if (symptom.symptomId === action.payload.symptomId) {
          symptom.isEditable = action.payload.isEditable;
        }
      });
      return Object.assign({}, state, {
        symptoms: symptoms
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
