export const defaultState = {
  authToken: null,
  doctorId: -1,
  doctorFirstName: null,
  doctorLastName: null,
  doctorPhone: null,
  doctorCode: null,
  doctorEmail: null,
  doctorDegrees: null,
  doctorExperience: null,
  doctorCharges: null,
  doctorCity: null,
  doctorUuid: null,
  followupDays: null,
  verticalId: -1,
  verticalName: null,
  verticalDescription: null,
  totalVisits: null,
  isPartnerDoctor: false,
  partnerSponsorId: null,
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return Object.assign({}, state, action.payload.userInfo);
    case 'SET_AUTH_TOKEN':
      return Object.assign({}, state, {
        authToken: action.payload,
      });
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
