export const defaultState = {};

export default function loginError(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN_ERROR': {
            return Object.assign({}, state, action.payload);
        }
        default:
            return state;
    }
}
