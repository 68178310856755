export const defaultState = {};

export default function prescription(state = defaultState, action) {
  switch (action.type) {
    case 'SAVE_PRESCRIPTION': {
      return Object.assign({}, state, {
        [action.payload.consultationId]: action.payload.prescription
      });
    }
    case 'DELETE_SAVED_PRESCRIPTION': {
      return Object.assign({}, state, {
        [action.payload.consultationId]: null
      });
    }
    default:
      return state;
  }
}
