import axios from 'axios';
import config from '../config';
import { showError } from '../utils';
import { store } from '../store';

export function getSymptoms(
  authToken,
  consultationId,
  query,
  type = 'symptom'
) {
  const headers = {
    authorization: authToken,
  };

  const params = {
    consultationId,
    query,
    type,
  };

  return axios
    .get(`${config.apiBaseUrl}/new-doctor/consultations/symptoms`, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDigitiseSymptoms(authToken, digitisationRequestId) {
  const headers = {
    authorization: authToken,
  };

  const params = {};

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/symptoms/get/${digitisationRequestId}`,
      {
        headers,
        params,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveSymptom(authToken, symptom) {
  const headers = {
    authorization: authToken,
  };

  const state = store.getState();
  let url = `${config.apiBaseUrl}/new-doctor/consultations/symptoms/save`;
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/symptoms/add`;
  }

  return axios
    .post(url, symptom, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteSymptom(authToken, symptom) {
  const headers = {
    authorization: authToken,
  };

  const state = store.getState();
  let url = `${config.apiBaseUrl}/new-doctor/consultations/symptoms/delete`;
  if (state.app.transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/symptoms/remove`;
  }

  return axios
    .post(url, symptom, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

// digitisations/requests/conditions/add
export function saveDigitizeConditions(
  authToken,
  conditions,
  userId,
  digitisationId
) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/requests/conditions/add`,
      {
        userId,
        digitisationId,
        conditions,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function saveConditions(authToken, conditions, userId, consultationId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/conditions`,
      {
        userId,
        consultationId,
        conditions,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getConditions(authToken, userId) {
  const headers = {
    authorization: authToken,
  };

  const params = {
    userId,
  };

  return axios
    .get(`${config.apiBaseUrl}/new-doctor/consultations/conditions/previous`, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getDigitizeConditions(authToken, userId) {
  const headers = {
    authorization: authToken,
  };

  const params = {
    userId,
  };

  return axios
    .get(`${config.apiBaseUrl}/digitisations/requests/conditions/previous`, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendSymptoms(authToken, consultationId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/symptoms/send`,
      {
        consultationId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getConditionsSearch(
  authToken,
  query,
  transcriberAccountActive
) {
  const headers = {
    authorization: authToken,
  };

  const params = {
    query,
  };

  let url = `${config.apiBaseUrl}/new-doctor/consultations/conditions`;
  if (transcriberAccountActive) {
    url = `${config.apiBaseUrl}/digitisations/requests/conditions/get`;
  }

  return axios
    .get(url, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteCondition(authToken, conditionId, userId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/conditions/delete`,
      {
        conditionId,
        userId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteDigitizeCondition(authToken, conditionId, userId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/requests/conditions/remove`,
      {
        conditionId,
        userId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchSymptoms({authToken, consultationId, searchKey}) {
  const headers = {
    authorization: authToken,
  };

  const params = {
    consultationId,
    searchKey
  };

  return axios.get(`${config.apiBaseUrl}/new-doctor/symptoms`, {
    params,
    headers
  })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function saveSymptomNew({authToken, symptomName}) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    symptomName
  };

  return axios.post(`${config.apiBaseUrl}/new-doctor/symptoms/add`, body, { headers})
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function actionOnSymptom({authToken, consultationId, symptomId, questions, actionType}) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    consultationId,
    symptomId,
    questions,
    actionType
  };

  return axios.post(`${config.apiBaseUrl}/new-doctor/consultations/symptoms`, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
