import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from './StyledTappable';
import { BoldText, RegularText } from './Text';
import { FaTimes } from 'react-icons/fa';
import { BiErrorAlt } from 'react-icons/bi';
import { hideError } from '../../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  font-size: 1.75rem;
`;

const ModalIcon = styled(BiErrorAlt)`
  font-size: 5rem;
  color: #f30;
`;

const ModalText = styled(RegularText)`
  font-size: 1.6rem;
  font-weight: 600;
`;

const ModalTitle = styled(BoldText)`
  font-size: 1.5rem;
  margin: 0.625rem 0rem;
  color: #555;
  font-weight: 500;
`;

const ErrorComponent = ({ appError, dispatch }) => {

    const modalRef = useRef(null);

    const hideModal = useCallback(() => {
        dispatch(hideError());
    }, [ dispatch ]);

    useEffect(() => {

        const handleClick = (e) => {
            if (modalRef?.current && !modalRef.current.contains(e.target)) {
                hideModal();
            }
        }
        
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);

    }, [ hideModal ]);

    if (!appError.hasError) {
        return <></> 
    }

    return <Modal>
        <ModalContainer>
            <ModalBody ref = {modalRef}>
                <CloseContainer onTap={hideModal}><FaTimes /></CloseContainer>
                <ModalIcon />
                <ModalTitle>{appError.errorTitle}</ModalTitle>
                <ModalText>
                    {typeof appError.errorDesc === 'string' ? appError.errorDesc : 'Try again later'}
                </ModalText>
            </ModalBody>
        </ModalContainer>
    </Modal>
};

const matchStateToProps = (state) => ({
  appError: state.app.appError
})

export default connect(matchStateToProps)(ErrorComponent);
