export const defaultState = {};

export default function lastDigitisation(state = defaultState, action) {
    switch (action.type) {
        case 'SET_LAST_DIGITISATION': {
            return Object.assign({}, action.payload);
        }
        default:
            return state;
    }
}
