const defaultState = {
    sessionId: null,
    token: null,
    roomName: '',
    authToken: null,
    userId: null,
    userName: '',
    doctorName: '',
    profileImg: null,
    doctorId: null,
    startCall: false
};


export default function videoSession(state = defaultState, action) {
    switch(action.type) {
        case 'SET_VIDEO_DATA':
            return Object.assign({}, state, {
                sessionId: action.payload.sessionId || state.sessionId,
                token: action.payload.doctorToken || state.token,
                roomName: action.payload.roomName || state.roomName,
                authToken: action.payload.authToken || state.authToken,
                userId: action.payload.userId || state.userId,
                userName: action.payload.userName || state.userName,
                doctorName: action.payload.doctorName || state.doctorName,
                profileImg: action.payload.profileImg || state.profileImg,
                doctorId: action.payload.doctorId || state.doctorId,
                startCall: action.payload.startCall || state.startCall,
            });
        case 'RESET_VIDEO_DATA': 
            return Object.assign({}, state, defaultState);
        default:
            return state;
    }
}